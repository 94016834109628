import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'first'
})
export class FirstKeyPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {

        if (!value) {
            return null;
        }

        const keys = Object.keys(value);
        if (keys && keys.length > 0) {
            return keys[0];
        }

        return null;
    }
}
