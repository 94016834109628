import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SHARED_COMPONENTS } from './components';
import { IonicModule } from '@ionic/angular';

import { SHARED_PIPES } from './pipes';
import { SHARED_GUARDS } from './guards';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
    ],
    declarations: [
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES
    ],
    providers: [
        ...SHARED_GUARDS
    ],
    exports: [
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ]
})
export class SharedModule { }
