import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-form-validation-error',
    template: `
    <div class="error-container">
        <div class="error-message" *ngIf="control"	[hidden]="!(!control.valid && control.touched)">
            <ion-icon name="information-circle-outline"></ion-icon>
            <span *ngIf="( control.errors | first ) === 'required'">The value is required.</span>
            <span *ngIf="( control.errors | first ) === 'maxError'">
                The value can't be greater than {{ control.getError('maxError').maxValue }}.
            </span>
            <span *ngIf="( control.errors | first ) === 'minError'">
                The value can't be smaller than {{ control.getError('minError').minValue }}.
            </span>
            <span *ngIf="( control.errors | first ) === 'isUrl'">The value is not a valid url.</span>
            <span *ngIf="( control.errors | first ) === 'isMac'">The value is not a valid mac address.</span>
            <span *ngIf="( control.errors | first ) === 'macAlreadyExists'">
                A device with the mac address '{{ control.getError('macAlreadyExists').mac }}' already exists.
            </span>
            <span *ngIf="( control.errors | first ) === 'nameAlreadyExists'">
                A device with the name '{{ control.getError('nameAlreadyExists').name }}' already exists.
            </span>
        </div>
    </div>
    `,
    styles: [`
        .error-container {
            margin-left: 15px;
            margin-top: 4px;
        }

        .error-message {
            margin: calc(var(--page-margin)/ 2) 0;
            display: flex;
            align-items: center;
            color: var(--ion-color-danger);
            font-size: 14px;
        }
    `]
})
export class FormValidationErrorComponent {

    @Input() control: AbstractControl;
}
